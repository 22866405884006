import { graphql } from "gatsby"
import React from "react"

import MainLayout from "../components/main-layout"

import { Container } from "semantic-ui-react"

export default({ data}: any) => {

  return <MainLayout>
    <Container text dangerouslySetInnerHTML={{ __html: data.markdownRemark.html}}/>
  </MainLayout>
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
      }
    }
  }
`
